<template>
    <div class="box" style="padding-top: 80px; box-sizing: border-box;">
        <Navigation nameColor="rgba(51, 51, 51, 1)" />
        <div class="crumbs">
            <div class="crumbs_ct flex">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/product' }">产品合作</el-breadcrumb-item>
                    <el-breadcrumb-item>可信网站认证</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
        <img :src="image" class="advertising" />
        <div class="credible_box">
            <credible_title title="可信网站核心价值" />
            <div class="credible_ul flex">
                <div class="credible_li">
                    <p>权威机构支持，验证结果真实</p>
                    <div class="credible_li_content">
                        <span>帮助知名网站防范钓鱼网站，维护网站形象；</span>
                        <span>帮助中小企业网站破解诚信难题，赢得客户信任；</span>
                        <span>帮助网民便捷核验网站真实身份，防范被骗。</span>
                    </div>
                </div>
                <div class="credible_li">
                    <p>防范恶意假冒，树立网站信任</p>
                    <div class="credible_li_content">
                        <span>可信网站被两大权威数据库共同收录；</span>
                        <span>可信网站在主流浏览器全面收录和展示；</span>
                        <span>可信网站在主流搜索引擎全面引用和展示;</span>
                        <span>可信网站验证全面支持安全软件、微博平台。</span>
                    </div>
                </div>
                <div class="credible_li">
                    <p>网站一次验证，结果全网通用</p>
                    <div class="credible_li_content">
                        <span>第三方权威机构的评定，为网站建立信用背书；</span>
                        <span>多重数据库对比，验证结果真实；</span>
                        <span>体现权威，公正，彰显身份，赢得信任。</span>
                    </div>
                </div>
                <div class="credible_li">
                    <p>监护网站运行，保障网站安全</p>
                    <div class="credible_li_content">
                        <span>第三方权威机构的评定，为网站建立信用背书；</span>
                        <span>多重数据库对比，验证结果真实；</span>
                        <span>体现权威，公正，彰显身份，赢得信任。</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="versions_box">
            <credible_title title="可信网站认证版本" />
            <ul class="versions_ul flex">
                <li :style="{ borderColor: versions_hr[0] }">
                    <i class="top_hr" :style="{ background: versions_hr[0] }"></i>
                    <p class="versions_t" :style="{ borderColor: versions_hr[0] }">标准版</p>
                    <div class="versions_money" :style="{ color: versions_hr[0] }">
                        <p>￥3000</p>
                        <span>每年</span>
                    </div>
                    <p class="versions_text">网站身份验证</p>
                    <p class="versions_text">网站运营监护</p>
                    <p class="versions_text">统一样式和唯一编号的证书</p>
                    <p class="versions_text">浏览器提示，搜索引擎展示</p>
                    <p class="versions_text"> 一次最多可申请10年</p>
                    <a href="#footer" class="versions_button" :style="{ background: versions_hr[0] }">立即购买</a>
                </li>
                <li :style="{ borderColor: versions_hr[1] }">
                    <i class="top_hr" :style="{ background: versions_hr[1] }"></i>
                    <p class="versions_t" :style="{ borderColor: versions_hr[1] }">行业验证版</p>
                    <div class="versions_money" :style="{ color: versions_hr[1] }">
                        <p>￥5000</p>
                        <span>每年</span>
                    </div>
                    <p class="versions_text">网站身份验证+行业资质验证</p>
                    <p class="versions_text">网站运营监护</p>
                    <p class="versions_text">统一样式和唯一编号的证书</p>
                    <p class="versions_text">浏览器提示，搜索引擎展示</p>
                    <p class="versions_text">证书和牌匾</p>
                    <a href="#footer" class="versions_button" :style="{ background: versions_hr[1] }">立即购买</a>
                </li>
                <li :style="{ borderColor: versions_hr[2] }">
                    <i class="top_hr" :style="{ background: versions_hr[2] }"></i>
                    <p class="versions_t" :style="{ borderColor: versions_hr[2] }">实地展示版</p>
                    <div class="versions_money" :style="{ color: versions_hr[2] }">
                        <p>￥5000</p>
                        <span>每年</span>
                    </div>
                    <p class="versions_text">网站身份验证</p>
                    <p class="versions_text">网站运营监护</p>
                    <p class="versions_text">统一样式和唯一编号的证书</p>
                    <p class="versions_text">浏览器提示，搜索引擎展示，企业实景展示</p>
                    <p class="versions_text"> 一次最多可申请10年</p>
                    <a href="#footer" class="versions_button" :style="{ background: versions_hr[2] }">立即购买</a>
                </li>
            </ul>
        </div>
        <div class="platform_box">
            <credible_title title="可信网站认证平台展示" />
            <div class="platRadio_box">
                <div class="flex">
                    <p>网站下方颁发红色的可信网站字样的logo标识</p>
                    <img src="../assets/credible/squire1.png" alt="">
                </div>
                <div class="flex">
                    <img src="../assets/credible/squire2.png" alt="">
                    <p>各大浏览器展示</p>
                </div>
                <div class="flex">
                    <p>可信网站电子证书</p>
                    <img src="../assets/credible/squire1.png" alt="">
                </div>
                <div class="flex">
                    <img src="../assets/credible/squire1.png" alt="">
                    <p>搜索引擎展示</p>

                </div>
            </div>
        </div>
        <div class="server_box">
            <credible_title title="可信网站认证增值服务" />
            <div class="server_img_box flex">
                <div class="serve_img">
                    <img src="../assets/credible/img1.png" alt="">
                    <p>颁发可信网站牌匾</p>
                </div>
                <div class="serve_img">
                    <img src="../assets/credible/img2.png" alt="">
                    <p>开通运行保监护</p>
                </div>
                <div class="serve_img">
                    <img src="../assets/credible/img3.png" alt="">
                    <p>网络媒体报道</p>
                </div>
                <img class="serve_money" src="../assets/credible/money.png" alt="">
            </div>
        </div>
        <div class="advantage_box">
            <credible_title title="选择我们进行认证的优势" />
            <div class="advantage flex">
                <div class="advantage_li" v-for="(item, index) in advantage" :key="index">
                    <div class="squire_box">
                        <p class="squire_index">0{{ index + 1 }}</p>
                    </div>
                    <div class="advantage_bg">
                        <p>{{ item.title }}</p>
                        <span>{{ item.content }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="case_box">
            <credible_title title="可信网站认证案例" />
            <div class="case_banner flex">
                <img src="../assets/credible/case1.jpg" alt="">
                <img src="../assets/credible/case2.jpg" alt="">
                <img src="../assets/credible/case3.jpg" alt="">
                <img src="../assets/credible/case4.jpg" alt="">
                <img src="../assets/credible/case5.jpg" alt="">
            </div>
        </div>
        <div class="footer_box" id="footer">
            <p class="footer_title">可信网站认证申请</p>
            <p class="mobile">如您急需申请，请联系我们：{{ webUrl.cooperate_tel }}</p>
            <div class="footer_form flex">
                <div class="form_input flex">
                    <p class="form_text">公司名称</p>
                    <div class="input">
                        <el-input v-model="form.company" placeholder="输入公司名称"></el-input>
                    </div>
                </div>
                <div class="form_input flex">
                    <p class="form_text">联系人姓名</p>
                    <div class="input">
                        <el-input v-model="form.contact" placeholder="输入联系人姓名"></el-input>
                    </div>
                </div>
                <div class="form_input flex">
                    <p class="form_text">联系人电话</p>
                    <div class="input">
                        <el-input v-model="form.tel" placeholder="输入联系人电话"></el-input>
                    </div>
                </div>
                <div class="form_input flex">
                    <p class="form_text">申请验证网站地址</p>
                    <div class="input">
                        <el-input v-model="form.url" placeholder="输入网址:http://"></el-input>
                    </div>
                </div>
                <div class="form_code flex">
                    <p class="form_text">验证码</p>
                    <div class="input">
                        <el-input @keyup.enter.native="postCred" v-model="form.captcha" placeholder="请输入验证码"></el-input>
                    </div>
                    <img @click="codeFn" :src="code" alt=""
                        style="width: 7rem; cursor: pointer;border: 0.17rem solid rgba(217, 217, 217, 1);border-radius: 0.4rem;" />
                </div>
                <button class="form_up" @click="postCred">立即提交</button>
            </div>
        </div>
        <footerBot />
    </div>
</template>

<script>
import credible_title from '../components/credible_title.vue';
export default {
    name: '',
    components: {
        credible_title
    },
    created() {
        this.getBanner()
        this.codeFn()
    },
    mounted() {

    },
    data() {
        return {
            image: '',
            versions_hr: ['rgb(255, 106, 69)', 'rgb(38, 171, 91)', 'rgb(71, 127, 255)'],
            //优势
            advantage: [
                {
                    title: '12年行业专注',
                    content: '全国首家网站认证服务商，36000家企业一致的首选'
                },
                {
                    title: '1对1专属客服',
                    content: '专业顾问全程跟进处理，快速解决用户面临问题'
                },
                {
                    title: '任何无隐性消费',
                    content: '认证费用明码标价，签订正式合同，认证不通过全额退款'
                },
                {
                    title: '一站式网站认证服务',
                    content: '国内主流网站认证服务应有尽有'
                },
                {
                    title: '急速办理  下证快',
                    content: '熟悉内部适合要点，VIP快速审批通道'
                },
                {
                    title: '客户提交信息保密',
                    content: '有数据保密系统，由专人管理客户数据，信息严格保密'
                },
            ],
            code: '', //生成的验证码
            form: {
                company: '', //公司名称
                contact: '', //联系人名称
                tel: '', //联系人电话
                url: '', //网址
                captcha: '', //验证码
            },
            webUrl: {},
        }
    },
    methods: {
        getBanner() {
            this.curlGet('/api/advert/detail', {
                mark: 'attestation_banner_01'
            }).then(res => {
                console.log(res.data);
                if (res.data.code) {
                    this.image = res.data.data.image
                }
                this.getwebsite()
            })
        },
        codeFn() {
            this.curlGetCode('/api/common/captcha').then((res) => {
                if (res.status == 200) {
                    // 以下为精髓
                    let blob = new Blob([res.data]) // 返回的文件流数据
                    let url = window.URL.createObjectURL(blob) // 将他转化为路径
                    this.code = url // 将转换出来的路径赋值给变量，其实和上一步结合就可以
                }
            })
        },
        getwebsite() {
            this.curlGet('/api/index/website').then(res => {
                if (res.data.code) {
                    this.webUrl = res.data.data
                }
            })
        },
        postCred() {
            this.curlPost('/api/creadibleapp/save', this.form).then(res => {
                console.log(res);
                if (res.data.code) {
                    this.$message({
                        message: '提交成功,等待客服回馈',
                        type: 'success'
                    })
                    setTimeout(() => {
                        this.form = {
                            company: '', //公司名称
                            contact: '', //联系人名称
                            tel: '', //联系人电话
                            url: '', //网址
                            captcha: '', //验证码
                        }
                        this.codeFn()
                },500)

                } else {
                    this.codeFn()
                    this.form.captcha = ''
                    return this.$message({
                        message: res.data.msg,
                        type: 'error',
                        offset: 100,
                    });
                }
            })
        }
    },
}
</script>

<style lang=scss scoped>
@import '@/scss/credible'
</style>